import React, {useState} from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-6.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";
import { COLORS, PAYPAL } from "website/utils/constants";

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import PayPalSubscribeButton from "./PayPalSubscribeButton";
import PayPalPurchaseButton from "./PayPalPurchaseButton";

const HeaderContainer = tw.div`w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const PlanDurationSwitcher = tw.div`block w-full max-w-xs sm:inline-block sm:w-auto border-2 rounded-full px-1 py-1 mt-8`;
const SwitchButton = styled.button`
  ${tw`w-1/2 sm:w-32 px-4 sm:px-8 py-3 rounded-full focus:outline-none text-sm font-bold text-black transition duration-300`}
  ${props => props.active && tw`bg-darker_theme_primary text-white`}
`;

const PlansContainer = tw.div`flex justify-center flex-col md:flex-row items-center md:items-start relative`;
const Plan = styled.div`
  ${tw`w-full max-w-72 mt-16 md:mr-12 md:last:mr-0 text-center px-8 rounded-lg relative text-gray-900 bg-white flex flex-col shadow-raised`}

  ${props =>
    props.featured &&
    css`
      ${tw`border-2 border-gray-200 shadow-none`}
    `}
`;

const PlanHeader = styled.div`
  ${tw`flex flex-col leading-relaxed py-8 -mx-8 bg-gray-100 rounded-t-lg`}
  .name {
    ${tw`font-bold text-xl`}
  }
  .price {
    ${tw`font-bold text-4xl sm:text-5xl my-1`}
  }
  .slash {
    ${tw`text-xl text-gray-500`}
  }
  .duration {
    ${tw`lowercase text-gray-500 font-medium tracking-widest`}
  }
  .mainFeature {
    ${tw`text-gray-500 text-sm font-medium tracking-wide`}
  }
`;
const PlanFeatures = styled.div`
  ${tw`flex flex-col -mx-8 px-8 py-8 flex-1 text-sm`}
  .feature {
    ${tw`mt-5 first:mt-0 font-semibold text-gray-500`}
  }
`;

const PlanAction = tw.div`px-4 pb-8`;
const BuyNowButton = styled(PrimaryButtonBase)`
  ${tw`rounded-full tracking-wider py-4 w-full text-sm hover:shadow-xl transform hocus:translate-x-px hocus:-translate-y-px focus:shadow-outline`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-25 transform -translate-x-2/3 -translate-y-1/2`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-25 transform translate-x-2/3 translate-y-1/2 fill-current text-teal-300`}
`;

export default ({
  setSelected = null,
  subheading = "Start your transformation now!",
  heading = "Become fit",
  plans = {},
}) => {

  const text_switch = ["Outdoors", "Online", "Home"];
  const OUTDOORS = text_switch[0].toLowerCase().replace(/ /g, "_");

  const [open, setOpen] = useState(false);
  const [activeDurationIndex, setActiveDurationIndex] = useState(0);
  const [selected_plans, setSelectedPlans] = useState(OUTDOORS)
  
  const text = {
    home: "Transform your home into your fitness sanctuary, with personalised guidance and support delivered right to your doorstep.",
    outdoors: "Great for larger groups or corporate teams aiming to boost morale and fitness levels collectively.",
    online: "Get fit from the comfort of your home, train whenever you prefer, on your terms, with expert guidance and support."
  }

  const width = "33.3%";

  const show_button = (current_plan) => {

    if(current_plan.plan_id === undefined) return <PayPalPurchaseButton amount={current_plan.amount} currency={PAYPAL.currency} setOpen={setOpen} />
    
    return <PayPalSubscribeButton plan_id={current_plan.plan_id} setOpen={setOpen} />
  }

  const modal_style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  return (
    <Container>
      <Modal open={open} onClose={()=> {setOpen(false)}}>

      <Box sx={modal_style}>
        <Typography id="modal-modal-title" variant="h4" component="h2">
          Successful Purchase!
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Thank you for the purchase! We will get in touch with you within the next 24 hours. Keep an eye on your mailbox!
        </Typography>
      </Box>

      </Modal>

      <PayPalScriptProvider
        options={{
          clientId: PAYPAL.client_id,
          components: "buttons",
          currency: PAYPAL.currency,
          vault: true,
        }}
      >
        <ContentWithPaddingXl>
          <HeaderContainer>
            {subheading && <Subheading style={{color: COLORS.dark_primary}}>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            <Description>{text[selected_plans]}</Description>
          <PlanDurationSwitcher style={{border: `1px solid ${COLORS.dark_primary}`, width: width}}>
            {text_switch.map((planDuration, index) => (
              <SwitchButton active={activeDurationIndex === index} key={index} onClick={() => {
                const text = text_switch[index].toLowerCase().replace(/ /g, "_")
                setActiveDurationIndex(index);
                setSelectedPlans(text);
                if(setSelected){
                  setSelected(text)
                }
              }} style={{border: "none", width: width, textAlign: "center", justifyContent: "center", paddingLeft: 20.5, paddingRight: 20.5}}>{text_switch[index]}</SwitchButton>
            ))}
          </PlanDurationSwitcher>
          </HeaderContainer>
          
          <PlansContainer>
            {
              plans[selected_plans].map((current_plan, index) => (

                <Plan key={index} featured={current_plan.featured}>
                  <PlanHeader>
                    <span className="priceAndDuration">
                      <span className="price">{current_plan.price}</span>
                      <span className="slash"> / </span>
                      <span className="duration">{current_plan.duration}</span>
                    </span>
                    <span className="name">{current_plan.name}</span>
                    <span className="mainFeature">{current_plan.main_feature}</span>
                  </PlanHeader>
                  <PlanFeatures>
                    {current_plan.features.map((feature, index) => (
                      <span key={index} className="feature">
                        {feature}
                      </span>
                    ))}
                  </PlanFeatures>
                  <PlanAction>
                    
                    {show_button(current_plan)}                    

                  </PlanAction>
                </Plan>
              ))
            }
          </PlansContainer>
        </ContentWithPaddingXl>
      </PayPalScriptProvider>
    </Container>
  );

};
