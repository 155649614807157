import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Navigation from "./components/Navigation";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import Pricing from "./components/Pricing";
import { FAQs, IMAGES, PLANS } from "./utils/text";


export default () => {

  const [selected, setSelected] = React.useState("outdoors")


  return <AnimationRevealPage disabled>
          <Navigation />
          
          <Pricing plans={PLANS}/>

          <FAQ faqs={FAQs.generic} imageSrc={IMAGES.faq.general} heading="Common FAQs"/>
          <FAQ faqs={FAQs.home} imageSrc={IMAGES.faq.home} heading="Home training FAQs"/>
          <FAQ faqs={FAQs.online} imageSrc={IMAGES.faq.online} heading = "Online training FAQs"/>
          <FAQ faqs={[...FAQs.outdoors_one_to_one, ...FAQs.outdoors_group]} imageSrc={IMAGES.faq.outdoor} heading = "Outdoor training FAQs"/>
          
          <Footer />
        </AnimationRevealPage>
}
